import {useState, useCallback} from 'react';
import utils from './Utils';
import toast from 'react-hot-toast';

/**
 * @returns {void}
 */
const useValidateShipmentNum = () => {
    const [shNumValid, setShNumValid] = useState<string>('');
    const [validating, setValidating] = useState<boolean>(false);
    const [validateTimeout, setValidateTimeout] = useState(null);

    // If validateCoripo is true it will check the shipmentNr in coripo fallback CN
    // else it will check shipmentNr in redis fallback CN
    const validateShipmentNumCN = useCallback(async (value, validateCoripo = false) => {
        setShNumValid('');
        setValidating(true);
        clearTimeout(validateTimeout);

        setValidateTimeout(setTimeout(async () => {
            let error = true;

            const endPoint = validateCoripo ? 'request/validate-shipment-num-coripo/' : 'tracking/validate-shipment-num/';
            const response = await utils.fetch(`${endPoint}` + value, false).catch((errorData) => {
                // if code 504 print error label
                if (errorData?.response?.data?.code === 504) {
                    error = false;
                    toast.error(utils.useTranslate('LBL_REQUEST_CREATE_SH_NUM_ERROR_UNREACHABLE'));
                    setShNumValid('unreachable');
                    return;
                }

                console.error('error', errorData?.response?.data);
                toast.error(utils.useTranslate('LBL_REQUEST_CREATE_SH_NUM_ERROR'));
                setShNumValid('invalid');
            });

            if (response?.status === true) {
                toast.success(utils.useTranslate('LBL_REQUEST_CREATE_SH_NUM_SUCCESS'));
                setShNumValid('valid');
            } else {
                if (error) {
                    toast.error(utils.useTranslate('LBL_REQUEST_CREATE_SH_NUM_INVALID'));
                    setShNumValid('invalid');
                }
            }
            setValidating(false);
        }, 1500));
    }, [validateTimeout]);

    return {shNumValid, validating, validateShipmentNumCN, setShNumValid};
};

export default useValidateShipmentNum;
