import React, {useContext, Suspense} from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import AppContext from '@context/AppContext';
import Loader from '@components/Loader';

const AdvancedTrackingComponent = React.lazy(() => import('@components/AdvancedTrackingComponent'));

export default function AdvancedTracking() {
    const {defs} = useContext(AppContext);

    return (
        <div className='pageContainer'>
            <Header menuItems={defs.menu} onLogin={() => {}} />

            <div className='pageContent'>
                <div className='advancedTrackingWrapper'>
                    <Suspense fallback={<Loader />}>
                        <AdvancedTrackingComponent />
                    </Suspense>
                </div>
            </div>

            <Footer footerItems={[]} fromContext={true} />
        </div>
    );
}
