declare const window: any;

class Utils {
    [x: string]: any;
    // utils functions are defined here in ./utils and dynamically added to Utils class
    // loaded via ./exportConfig.ts - like sAction in coripo

    constructor() {
        // constructor
        this.config = window?.config?.app;
        this.apiUrl = this.config?.api;
        this.lang = null;
        this.packageRegexes = [];
    }
}

const utils = new Utils();

import * as fn from './exportConfig';

const arrName = Object.keys(fn);
const arr = arrName.map((key) => fn[key]);
arr.forEach(function(func, key) {
    Utils.prototype[arrName[key]] = func;
});

export default utils;
