/**
 * useLoadRegexes hook
 * @returns {void}
 */
export default function useLoadRegexes() {
    /**
     * queryData - fetch data from API
     * @returns {Promise}
     */
    const queryRegexes = async () => {
        const response = await this.fetch('packageRegexes/get');

        if (response) {
            this.packageRegexes = response;
        }
    };

    queryRegexes();

    if (this.packageRegexes.isError && !this.packageRegexes.isLoading) {
        console.error('useLoadRegexes >> regexes is unable to load', this.packageRegexes);
        throw new Error(this.packageRegexes.error.message);
    }
}
