/**
 * validatePackageNumber function
 *
 * @param {string} packageNumber
 * @returns {Boolean}
 */
export default function validatePackageNumber(packageNumber: string) {
    for (const regex of this.packageRegexes) {
        const regexToTest = new RegExp(regex);
        if (regexToTest.test(packageNumber)) {
            return true; // Exit immediately if a match is found
        }
    }

    return false; // No match found
}
