import {createContext, useState, useCallback, Dispatch, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import utils from 'utils';
import Loader from '@/components/Loader';

interface IAppContext {
    user: User;
    setUser: Dispatch<User>;
    logOut: () => void;
    requestData: RequestData;
    setRequestData: Dispatch<RequestData>;
    popups: Popup[];
    castPopup: (popup: Popup) => string;
    closePopup: (id: string) => void;
    defs: Defs;
    activeTab: string;
    shipmentInfo: ShipmentInfo,
    setShipmentInfo: Dispatch<ShipmentInfo>,
    load: boolean,
    setLoad: SetLoad,
    shipmentNr: string,
    setShipmentNr: Dispatch<ShipmentNr>,
}

const defaultState: IAppContext = {
    user: undefined,
    setUser: () => {},
    logOut: () => {},
    requestData: utils.dataConstructors.RequestData,
    setRequestData: () => {},
    popups: [],
    castPopup: () => '',
    closePopup: () => {},
    defs: utils.dataConstructors.Defs,
    activeTab: '',
    shipmentInfo: utils.dataConstructors.ShipmentInfo,
    setShipmentInfo: () => {},
    load: false,
    setLoad: () => false,
    shipmentNr: '',
    setShipmentNr: () => '',
};

export const AppContext = createContext<IAppContext | null>(defaultState);

export const AppContextProvider = (props: any) => {
    // states of Context
    const [user, setUser] = useState<User>(undefined);
    const [defs, setDefs] = useState<Defs>(utils.dataConstructors.Defs);
    const [popups, setPopups] = useState<Popup[]>([]);
    const [requestData, setRequestData] = useState<RequestData>(utils.dataConstructors.RequestData);
    const [shipmentInfo, setShipmentInfo] = useState<ShipmentInfo>(utils.dataConstructors.ShipmentInfo);
    const [shipmentNr, setShipmentNr] = useState<string>('');
    const [activeTab, setActiveTab] = useState<string>('');
    const [load, setLoad] = useState<boolean>(false);

    // functions of Context (only functions that interact with states others should be in utils)
    /**
     * logOut - log out user
     */
    const logOut = () => {
        if (user?.type === 'internal') {
            utils.setStorage('internalUser', {});
            utils.setStorage('internalUserToken', '');
        }

        setUser(undefined);
        // redirect to homepage
        window.location.href = '/';
    };

    /**
     * castPopup - cast popup
     * @param {Popup} popup
     * @returns {string} id of popup
     */
    const castPopup = useCallback((popup: Popup) => {
        const newPopups = [...popups];
        const uuid = utils.generateUuid();
        newPopups.push({
            ...popup,
            id: uuid,
        });
        setPopups(newPopups);
        return uuid;
    }, [popups, setPopups]);

    /**
     * closePopup - close popup
     * @param {string} id
     */
    const closePopup = useCallback((id: string) => {
        const newPopups = popups.filter((popup) => popup.id !== id);
        setPopups(newPopups);
    }, [popups, setPopups]);

    // query data
    const queryDefs = useCallback(async () => {
        const response = await utils.fetch('ui/defs');
        return response;
    }, []);

    const queryDefsQuery = useQuery({queryKey: ['defs'], queryFn: queryDefs});

    useEffect(() => {
        if (!queryDefsQuery.isLoading && queryDefsQuery.isError) {
            console.error('queryDefsQuery error', queryDefsQuery.error);
            return;
        }

        if (!queryDefsQuery.isLoading && queryDefsQuery.data) {
            const defs = queryDefsQuery.data;
            // set active on menu items where link matches current url before / (e.g. /request/1234)
            const moduleFromUrl = window.location.pathname;
            defs.menu = defs.menu.map((menuItem) => {
                if (menuItem.link === moduleFromUrl) {
                    menuItem.active = true;
                    setActiveTab(menuItem.link);
                } else {
                    menuItem.active = false;
                }
                return menuItem;
            });
            setDefs(queryDefsQuery.data);
        }

        // load internal user from storage if exists
        const userStore = utils.getStorage('internalUser');
        if (userStore && !user) {
            setUser(userStore);
        }
    }, [queryDefsQuery, queryDefsQuery.data, user, setUser, setDefs]);

    utils.useLoadLangs();
    utils.useLoadRegexes();

    // exported values of Context
    const contextValue = {
        user,
        setUser,
        logOut,
        requestData,
        setRequestData,
        popups,
        castPopup,
        closePopup,
        defs,
        activeTab,
        shipmentInfo,
        setShipmentInfo,
        load,
        setLoad,
        shipmentNr,
        setShipmentNr,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {load ? <Loader /> : false}
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;
