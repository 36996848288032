/**
 *
 * @param {string} packageNr
 * @returns {string} formatted packageNr (remove -)
 */
export default function formatPackageNr(packageNr) {
    const formattedPackageNumber = packageNr.replace(/-/g, '');

    return formattedPackageNumber;
}
